import React, { Component } from "react";
import Allques from "../../subcompo/sections-quote/Allques";
import { setUWQues } from "../../../utils/session_data";
import { quesList } from "../../../utils/ques_list";
import { submitQues, updateQuesList } from "../../../utils/form_ques";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";
import { awsUrl2 } from "../../../config";
import axios from "axios";
class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: quesList,
      amtrustQuesList: [],
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.submitQues = submitQues.bind(this);
  }

  createQuestionsData = () => {
    let { quesList } = JSON.parse(JSON.stringify(this.state));
    for (let question of [...quesList]) {
      if (question.response === true) {
        question.response = "Y";
      } else if (question.response === false) {
        question.response = "N";
      }
    }

    return { quesList };
  };

  // amtrust Get Question on did mount of next page
  amtrustGetQuestionsResponse = (uuid) => {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let res = await axios.get(
          awsUrl2 + `/api/getAmtrustData/${uuid}/getQuestions`
        );
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });

    return promise;
  };
  // end of get amtrust Get Question on did mount of next page

  async componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    if (sessionStorage.getItem("currProspect")) {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      if (currProspect.uwQues) {
        let quesList = [];
        for (let ind in currProspect.uwQues) {
          quesList.push({
            ...currProspect.uwQues[ind],
            response: currProspect.uwQues[ind].response === "Y" ? true : false,
          });
        }

        this.setState({ quesList: quesList });
      }
      // // set the amtrust get questions if amtrust acount details exist means
      // let amtrustAccountDetails = JSON.parse(
      //   sessionStorage.getItem("amtrustAccountDetails")
      // );
      // if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
      //   debugger;
      //   try {
      //     let amtrustAccountDetails = sessionStorage.getItem(
      //       "amtrustAccountDetails"
      //     );

      //     try {
      //       amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
      //     } catch (error) {}

      //     if (amtrustAccountDetails && amtrustAccountDetails?.QuoteId) {
      //       let amtrustQuesList = [];
      //       let quoteId = amtrustAccountDetails.QuoteId;

      //       let amtrustTriggerResponse = await axios.get(
      //         awsUrl2 + `/api/triggerAmtrustGetQuestions/${quoteId}`
      //       );

      //       console.log(amtrustTriggerResponse, "getAmtrustQuestionsLocal");
      //       let uuid;
      //       if (amtrustTriggerResponse.data.uuid) {
      //         uuid = amtrustTriggerResponse.data.uuid;
      //       } else {
      //         throw "error in amtrust create quote trigger";
      //       }

      //       let amtrustQuestionsResponse;
      //       do {
      //         amtrustQuestionsResponse = await this.amtrustGetQuestionsResponse(
      //           uuid
      //         );
      //         if (amtrustQuestionsResponse === "error") {
      //           throw "error in amtrust get questions";
      //         }
      //       } while (amtrustQuestionsResponse === "processing");

      //       if (amtrustQuestionsResponse.StatusCode === 200) {
      //         let amtrustQuesText = [];
      //         console.log("++", amtrustQuestionsResponse.Data);

      //         for (let question of amtrustQuestionsResponse.Data) {
      //           if (!amtrustQuesText.includes(question.Question)) {
      //             let response;
      //             amtrustQuesText.push(question.Question);
      //             if (
      //               question.Answer &&
      //               question.Answer.toLowerCase() === "yes"
      //             ) {
      //               response = true;
      //             } else if (
      //               question.Answer &&
      //               question.Answer.toLowerCase() === "no"
      //             ) {
      //               response = false;
      //             }

      //             amtrustQuesList.push({
      //               name: question.Id,
      //               ques: question.Question,
      //               response,
      //             });
      //           }
      //         }
      //         this.setState({ amtrustQuesList });
      //       }
      //     } else if (currProspect.amtrustQuesList) {
      //       this.setState({ amtrustQuesList: currProspect.amtrustQuesList });
      //     } else {
      //       console.log("Amtrust quote not generated");
      //     }
      //   } catch (error) {
      //     console.log("error while setting amtrust questions", error);
      //   }
      // }
      // End of Amtrust Get Question  on did mount
    }
  }

  setQuestions = (quesList) => {
    let response = {};
    for (let question of quesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
        remarks: question.remarks,
      };
    }

    return response;
  };

  render() {
    const { quesList, incompleteQuestions, amtrustQuesList } = this.state;
    const { updatePopUpData } = this.props;

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      if (
        currProspect &&
        currProspect.companyProfile &&
        currProspect.companyProfile.companyName
      )
        compName = currProspect.companyProfile.companyName.value;
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className="container-fluid per98">
        <NewProgress />
        <div id="underWritingQues">
          <form>
            <div className="row no-gutters heading-row">
              <span className="formTitle title2 finallyquestionwidth">
                And finally, answers to some basic questions about your business
              </span>
              <p className="compName">{compName}</p>
            </div>
            <div className="table-responsive table-undrques-container">
              <Allques
                quesList={quesList}
                setUWQues={this.setUWQues}
                updateQuesList={this.updateQuesList}
              />
            </div>
            {incompleteQuestions && (
              <div className="errMsg text-center mb-4">
                Please answer all the questions!
              </div>
            )}
          </form>
          <div className="row">
            <div className="col-12 text-center">
              <button
                type="button"
                className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
                onClick={() => {
                  if (!hideButtons)
                    this.submitQues(quesList, updatePopUpData, amtrustQuesList);
                }}
                disabled={hideButtons}
              >
                Get Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FormUnderQues;

import { awsUrl, awsUrl2 } from "../config";
import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import {
  deleteAllCookies,
  showErrAlert,
  getHeader,
  blobPdfFromBase64String,
} from "./common";
import { Auth } from "aws-amplify";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";

const quote_dropped = require("./quote_dropped.json");

//ca, keyrisk => Starnet and midwest

const uuidv4 = require("uuid/v4");
const numeral = require("numeral");
var sizeof = require("object-sizeof");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");
const { carrierPeoProgramList, programMapping } = require("./carrierList.json");

let salesPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          resolve({
            salespersonName: sessionData.attributes.email,
            salespersonCheck: true,
          });
        } else {
          resolve({
            salespersonName: "",
            salespersonCheck: false,
          });
        }
      } else {
        resolve({
          salespersonName: "",
          salespersonCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salespersonName: "",
        salespersonCheck: false,
      });
    }
  });

  return promise;
};

export let checkQuestionsValidity = (quesList) => {
  let validQuestions = true;
  for (let question of [...quesList]) {
    if (question.response !== true && question.response !== false) {
      validQuestions = false;
      break;
    }
  }

  return validQuestions;
};

const getAvailableCarriers = (carrierEligibilityMap) => {
  let response = [];
  try {
    for (let carrier in carrierEligibilityMap) {
      // if (
      //   carrierEligibilityMap[carrier] === "yes" ||
      //   carrierEligibilityMap[carrier] === "uw"
      // ) {
      response.push(carrier);
      // }
    }
  } catch (error) {
    response = [];
  }

  return response;
};

export function amtrustResponse(uuid, apiType) {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let res = await axios.get(
        awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
}

export async function submitQues(quesList, updatePopUpData, amtrustQuesList) {
  $("#loader").css("display", "block");
  // let validQuestions = checkQuestionsValidity(quesList);
  // if (!validQuestions) {
  //   $("#loader").css("display", "none");
  //   this.setState({ incompleteQuestions: true });
  //   return;
  // }
  try {
    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let loggedIn_emailID = isLoggedIn.attributes.email;

    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    let isUwFlow = sessionStorage.getItem("uw_flow") ? true : false;
    let submissionDate = sessionStorage.getItem("submissionDate");

    let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");
    console.log(amtrustAccountDetails, "got an Amtrust Id");

    try {
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    } catch (error) {
      amtrustAccountDetails = {};
    }
    let user_id = sessionStorage.getItem("user_id");

    if (!user_id) {
      $("#loader").css("display", "none");
      return;
    }

    let uwQuesData = [];
    let carrierUserData = [];
    let addQuestionResponses = [];
    let addQuestionIndexMap = {
      q25: 0,
      q26: 1,
      q27: 2,
      q28: 3,
    };

    for (let question of quesList) {
      let dataQues = {
        response: question.response ? "Y" : "N",
        name: question.name,
        ques: question.ques,
        remarks: question.remarks,
      };
      if (question.name in addQuestionIndexMap) {
        let responseIndex = addQuestionIndexMap[question.name];
        addQuestionResponses[responseIndex] = question.response ? 1 : 0;
      }
      uwQuesData.push(dataQues);
    }

    currProspectDetails.uwQues = uwQuesData;
    sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));

    const {
      childrenLoc,
      companyProfile,
      emodStatesData,
      uwQues,
      peoDetails,
      carrierList,
      riskProfile,
    } = currProspectDetails;
    // let demoPeo = peoDetails?.selectedPeo === "demo";
    // if (demoPeo) {
    //   peoDetails.selectedPeo = "gms";
    // }
    if (childrenLoc && companyProfile && emodStatesData) {
      if (!companyProfile?.expectedExpiryDate?.value) {
        companyProfile.expectedExpiryDate = {
          value: moment(companyProfile.expectedStartDate.value).add(1, "year"),
        };
      }

      let dataBody = {
        common: {
          el_limits: "1000-1000-1000",
          date_from: moment(companyProfile.expectedStartDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.expectedExpiryDate.value).format(
            "YYYY-MM-DD"
          ),
          emod: [],
          questions: addQuestionResponses || [],
          sr_carriers: {},
        },
        offices: [],
      };

      let emodForMail;

      let emodStatesDataCopy = JSON.parse(JSON.stringify(emodStatesData));

      // console.log("emodStatesDataCopy before: ", emodStatesDataCopy);

      if (emodStatesDataCopy["ncci"]) {
        let ncciEmodValue1 = emodStatesDataCopy["ncci"]?.rate1?.value || "";
        let ncciEmodValue2 = emodStatesDataCopy["ncci"]?.rate2?.value || "";
        let date1 = emodStatesDataCopy["ncci"]?.date1?.value;
        let date2 = emodStatesDataCopy["ncci"]?.date2?.value;

        for (let ncci_state of emodStatesDataCopy["ncci"]?.stateList || []) {
          if (!(ncci_state in emodStatesDataCopy)) {
            emodStatesDataCopy[ncci_state] = {
              rate1: {
                value: ncciEmodValue1,
              },
              rate2: {
                value: ncciEmodValue2,
              },
              response: ncciEmodValue1 ? true : false,
              displayMore: emodStatesDataCopy["ncci"]?.displayMore,
              date1: { value: date1 || moment() },
              date2: { value: date2 || moment() },
            };
          }
        }
        delete emodStatesDataCopy["ncci"];
      }

      // console.log("emodStatesDataCopy after: ", emodStatesDataCopy);
      for (let state in emodStatesDataCopy) {
        let emodObj = {};
        if (emodStatesDataCopy[state].response) {
          emodObj = {
            value_1: emodStatesDataCopy[state].rate1.value,
            effective_date_1: moment(
              emodStatesDataCopy[state].date1.value
            ).format("YYYY-MM-DD"),
          };

          if (emodForMail == null && emodStatesDataCopy[state].rate1.value)
            emodForMail = emodStatesDataCopy[state].rate1.value;

          emodObj.stateList =
            state === "ncci" ? emodStatesDataCopy[state].stateList : [state];
          if (emodStatesDataCopy[state].displayMore) {
            emodObj.value_2 = emodStatesDataCopy[state]?.rate2?.value || "";
            emodObj.effective_date_2 = moment(
              emodStatesDataCopy[state].date2.value
            ).format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }

      // console.log("dataBody: ", dataBody);
      // return;

      let carrierEligBody = {};
      let stateLocMap = {};
      let payrollMap = {};

      //proRate payroll
      let total_days = moment(
        moment(companyProfile.expectedStartDate.value).add(1, "year")
      ).diff(moment(companyProfile.expectedStartDate.value), "days");

      let dates_diff_in_days = moment(
        companyProfile.expectedExpiryDate.value
      ).diff(moment(companyProfile.expectedStartDate.value), "days");

      let prorateValue = numeral(dates_diff_in_days)
        .divide(total_days)
        .value();

      if (Number(prorateValue) > 1) {
        prorateValue = 1;
      } else {
        prorateValue = Math.round(Number(prorateValue) * 1000) / 1000;
      }

      dataBody.common.prorateValue = Number(prorateValue);

      let has_ca_state = false;

      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          suta: "",
          employees: [],
          carriers: [],
        };

        if (childrenLoc[addressBlockNo].state.value.toLowerCase() === "ca")
          has_ca_state = true;

        let key = `${name}_${addressBlockNo}`;
        if (!(key in carrierEligBody)) {
          let state = key.split("_")[3].toLowerCase();
          if (state in stateLocMap) {
            key = stateLocMap[state];
          } else {
            carrierEligBody[key] = [];
            stateLocMap[state] = key;
          }
        }

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          carrierEligBody[key].push({
            code,
            description: desc,
            state,
          });

          if (!payrollMap[state]) {
            payrollMap[state] = {};
          }

          payrollMap[state][code] = payroll;

          let proRatedPayroll = numeral(payroll)
            .multiply(dates_diff_in_days)
            .divide(total_days)
            .value();

          childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].proRatedPayroll = {
            value: proRatedPayroll.toString(),
          };

          let _payroll;

          try {
            if (payroll?.includes("$") && payroll?.split("$")?.[1]) {
              _payroll = payroll
                .split("$")[1]
                .split(",")
                .join("");
            } else {
              _payroll = payroll.split(",").join("");
            }
          } catch (error) {}

          let classCodeObj = {
            code,
            payroll: Number(proRatedPayroll).toString(),
            original_payroll: _payroll,
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                    addressChildNo
                  ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      let quoteFactors = {};

      await axios
        .post(awsUrl + "/api/getCarrierElig", JSON.stringify(carrierEligBody))
        .then(async (res) => {
          let data = res.data.eligibility,
            nonEligibleMap;
          var eligData = res.data.eligibility;
          let industries = res.data.industries;
          let details = res.data.details;

          let eligibilityPriorityMap = {
            no: 3,
            uw: 2,
            yes: 1,
          };
          let carrierEligibilityMap = {};

          // // save amtrust Questions  starts here for now comment please don't uncomment it.

          // if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
          //   let amtrustSaveQuestionsRequestBody = [];
          //   for (let question of amtrustQuesList) {
          //     amtrustSaveQuestionsRequestBody.push({
          //       QuestionId: question.name,
          //       AnswerValue: question.response ? "yes" : "no",
          //     });
          //   }

          //   let amtrustSaveQuestionsTriggerResponse = await axios.post(
          //     awsUrl2 +
          //       `/api/triggerAmtrustSaveQuestions/${amtrustAccountDetails.QuoteId}`,
          //     amtrustSaveQuestionsRequestBody
          //   );

          //   let saveQuestionsUuid;
          //   if (
          //     amtrustSaveQuestionsTriggerResponse.data.uuid
          //     // &&amtrustSavePaymentPlansTriggerResponse.data.uuid
          //   ) {
          //     saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
          //     // savePaymentPlansUuid =
          //     //   amtrustSavePaymentPlansTriggerResponse.data.uuid;
          //   } else {
          //     throw "error in amtrust create quote trigger";
          //   }

          //   let amtrustQuestionsResponse;
          //   do {
          //     let promiseList = [
          //       amtrustResponse(saveQuestionsUuid, "saveQuestions"),
          //       // amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
          //     ];

          //     await Promise.all(promiseList)
          //       .then((res) => {
          //         console.log("++res", res);
          //         amtrustQuestionsResponse = res[0];
          //         // amtrustSavePaymentPlansResponse = res[1];
          //       })
          //       .catch((err) => {
          //         console.log("error", err);
          //         throw "error in amtrust quote creation";
          //       });
          //     if (
          //       amtrustQuestionsResponse === "error"
          //       // ||amtrustSavePaymentPlansResponse === "error"
          //     ) {
          //       throw "error in amtrust quote generation";
          //     }
          //   } while (
          //     amtrustQuestionsResponse === "processing"
          //     // ||amtrustSavePaymentPlansResponse === "processing"
          //   );
          // }
          // // end of save question in amtrust

          // //payment plan start here for Amtrust. after getting the quote id form Session Storage  we go ahead to after create quote with out UW question.

          if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
            try {
              let savePaymentPlansUuid;
              let amtrustSavePaymentPlansResponse;

              console.log(amtrustAccountDetails.QuoteId);
              let amtrustSavePaymentPlansTriggerResponse = await axios.get(
                awsUrl2 +
                  `/api/triggerAmtrustSavePaymentPlans/${amtrustAccountDetails.QuoteId}`
              );

              if (
                // amtrustSaveQuestionsTriggerResponse.data.uuid &&
                amtrustSavePaymentPlansTriggerResponse.data.uuid
              ) {
                // saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
                savePaymentPlansUuid =
                  amtrustSavePaymentPlansTriggerResponse.data.uuid;
              } else {
                throw "error in amtrust create quote trigger";
              }
              do {
                let promiseList = [
                  // amtrustResponse(saveQuestionsUuid, "saveQuestions"),
                  amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
                ];

                await Promise.all(promiseList)
                  .then((res) => {
                    console.log("++res", res);
                    // amtrustQuestionsResponse = res[0];
                    amtrustSavePaymentPlansResponse = res[0];
                  })
                  .catch((err) => {
                    console.log("error", err);
                    throw "error in amtrust quote creation";
                  });
                if (
                  // amtrustQuestionsResponse === "error" ||
                  amtrustSavePaymentPlansResponse === "error"
                ) {
                  throw "error in amtrust quote generation";
                }
              } while (
                // amtrustQuestionsResponse === "processing" ||
                amtrustSavePaymentPlansResponse === "processing"
              );
            } catch (error) {
              console.log("Failed while sending the payment plan", error);
            }
          }
          // //end of savepayment

          for (let location in data) {
            for (let carrier in data[location]) {
              if (data[location][carrier]) {
                if (!carrierEligibilityMap[carrier]) {
                  carrierEligibilityMap[carrier] =
                    data[location][carrier].value;
                } else if (
                  eligibilityPriorityMap[data[location][carrier].value] >
                  eligibilityPriorityMap[carrierEligibilityMap[carrier]]
                ) {
                  carrierEligibilityMap[carrier] =
                    data[location][carrier].value;
                  // let excludedList = data[location][carrier].excludedList;
                  // for (let item of excludedList) {
                  //   item.payroll = payrollMap[item.state][item.class_code];
                  // }
                }
              }
            }
          }
          let availableCarrierList = getAvailableCarriers(
            carrierEligibilityMap
          );
          let userSelectedCarriers = [];
          let carriersListToSend = [];

          for (let obj of carrierList.value) {
            userSelectedCarriers.push(obj.value);
          }

          for (let carrier of carrierPeoProgramList) {
            // let value = carrier.peo;
            // value = value
            //   .toLowerCase()
            //   .split(" ")
            //   .join("");
            if (
              userSelectedCarriers.includes(carrier.carrier) &&
              availableCarrierList.includes(carrier.carrier) &&
              (peoDetails.selectedPeo === carrier.peo ||
                (peoDetails.selectedPeo === "demo" && carrier.peo === "gms"))
            )
              carriersListToSend.push(carrier);
          }

          let additional_carr_list = [];
          let have_to_add_additional_carriers_in_ca = false;
          if (
            has_ca_state &&
            (userSelectedCarriers.includes("carrier_r") ||
              userSelectedCarriers.includes("carrier_aa"))
          ) {
            have_to_add_additional_carriers_in_ca = true;
            if (!userSelectedCarriers.includes("carrier_ac")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ac" &&
                  (peoDetails.selectedPeo === carr_obj.peo ||
                    (peoDetails.selectedPeo === "demo" &&
                      carr_obj.peo === "gms"))
                )
                  additional_carr_list.push(carr_obj);
              }
            }
            if (!userSelectedCarriers.includes("carrier_ab")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ab" &&
                  (peoDetails.selectedPeo === carr_obj.peo ||
                    (peoDetails.selectedPeo === "demo" &&
                      carr_obj.peo === "gms"))
                )
                  additional_carr_list.push(carr_obj);
              }
            }
          }

          //Zynergia peo for carrier midwest
          let carrList = [];
          if (
            peoDetails.selectedPeo === "zynergiahr" &&
            has_ca_state &&
            userSelectedCarriers.includes("carrier_r")
          ) {
            have_to_add_additional_carriers_in_ca = true;
            if (!userSelectedCarriers.includes("carrier_ab")) {
              for (let carr_obj of carrierPeoProgramList) {
                if (
                  carr_obj.carrier === "carrier_ab" &&
                  carr_obj.peo === "zynergiahr"
                ) {
                  carrList.push(carr_obj);
                }
              }
            }
            additional_carr_list = carrList;
          }

          if (availableCarrierList.length > 0) {
            for (let office of dataBody.offices) {
              if (
                office.state === "ca" &&
                have_to_add_additional_carriers_in_ca
              ) {
                office.carriers.push(
                  ...carriersListToSend,
                  ...additional_carr_list
                );
              } else {
                office.carriers.push(...carriersListToSend);
              }
            }

            const fetchStatesTypeData = (
              location,
              peo,
              carrier,
              state,
              program
            ) => {
              return new Promise((resolve, reject) => {
                try {
                  axios
                    .get(awsUrl + `/api/getStateTypeData/${state}/${program}`)
                    .then((res) => {
                      res?.data?.type
                        ? resolve({
                            location,
                            peo,
                            carrier,
                            type: res.data.type,
                          })
                        : resolve({});
                    })
                    .catch((err) => {
                      throw err;
                    });
                } catch (error) {
                  console.log(error);
                  resolve({});
                }
              });
            };

            const resID = await axios.post(
              awsUrl2 + `/api/getQuotesDataId`,
              JSON.stringify({ body: dataBody })
            );

            let id = resID.data.id;

            let fetchData = async (id) => {
              let dropped = true;
              await axios
                .get(awsUrl2 + `/api/getQuotesData/${id}`)
                .then(async (res) => {
                  if (res.data === "processing") {
                    const myTimeout = setTimeout(() => fetchData(id), 5000);
                  } else if (res.data === "error") {
                    $("#loader").css("display", "none");
                    this.setState({
                      errorMessage: "Oops!! Timed Out. Please try again.",
                    });
                  } else {
                    try {
                      let urlData = res.data;
                      let quoteDynamoDbRuns;

                      let urlResponse = await axios.get(urlData.url);
                      let data = urlResponse.data;

                      let stateTypePromiseList = [];

                      if (data) {
                        for (let location in data) {
                          let locDropped = true;
                          for (let peo in data[location]) {
                            let peoDropped = true;
                            for (let carrier in data[location][peo]) {
                              if (data[location][peo][carrier] !== "Dropped") {
                                peoDropped = false;
                                dropped = false;
                                locDropped = false;
                                if (!(peo in quoteFactors)) {
                                  quoteFactors[peo] = {};
                                }
                                if (!(carrier in quoteFactors[peo])) {
                                  quoteFactors[peo][carrier] = {
                                    teap: 0,
                                    total: 0,
                                    futaCharge: 0,
                                    ficaCharge: 0,
                                    sutaCharge: 0,
                                    payroll: 0,
                                    adminFee: 0,
                                  };
                                }
                                quoteFactors[peo][carrier].adminFee =
                                  data[location][peo][carrier].admin_fee;
                                quoteFactors[peo][carrier].teap += Number(
                                  data[location][peo][carrier]
                                    .total_estimated_annual_premium
                                );
                                quoteFactors[peo][carrier].total += Number(
                                  data[location][peo][carrier].total
                                );
                                quoteFactors[peo][carrier].futaCharge = Number(
                                  data[location][peo][carrier].futa
                                );
                                quoteFactors[peo][carrier].ficaCharge = Number(
                                  data[location][peo][carrier].fica
                                );
                                quoteFactors[peo][carrier].sutaCharge = Number(
                                  data[location][peo][carrier].suta
                                );
                              } else {
                                let manual_rate_values = {};
                                let currState = location
                                  .split("_")
                                  .reverse()[1]
                                  .toLowerCase();
                                let officesObj = dataBody.offices;
                                let employees = [];
                                let program, program_to_use;

                                for (let _program in programMapping) {
                                  let carrierPeoProgramObj =
                                    programMapping[_program];

                                  if (
                                    carrierPeoProgramObj.peo === peo &&
                                    carrierPeoProgramObj.carrier === carrier
                                  ) {
                                    program = _program;
                                    if (peo === "insurecomp")
                                      program_to_use = `${peo}-${carrierPeoProgramObj.actualCarrierName}`;
                                    break;
                                  }
                                }

                                stateTypePromiseList.push(
                                  fetchStatesTypeData(
                                    location,
                                    peo,
                                    carrier,
                                    currState,
                                    program_to_use?.toLowerCase() ||
                                      program?.toLowerCase()
                                  )
                                );

                                for (let office of officesObj) {
                                  if (office.state === currState)
                                    employees = office.employees;
                                }

                                for (let employee of employees) {
                                  manual_rate_values[employee.code] = 0;
                                }

                                //delete carrier from data
                                data[location][peo][carrier] = JSON.parse(
                                  JSON.stringify(quote_dropped)
                                );

                                data[location][peo][carrier].peo = peo;
                                data[location][peo][carrier].program = program;
                                data[location][peo][
                                  carrier
                                ].carriers = carriersListToSend;
                                data[location][peo][carrier].el_limits =
                                  dataBody.common.el_limits;
                                data[location][peo][carrier].date_from =
                                  dataBody.common.date_from;
                                data[location][peo][carrier].date_to =
                                  dataBody.common.date_to;
                                data[location][peo][carrier].carrier = carrier;
                                data[location][peo][carrier].carrier_email =
                                  carrier + "@gmail.com";
                                data[location][peo][
                                  carrier
                                ].employees = employees;
                                data[location][peo][
                                  carrier
                                ].original_employees = employees;
                                data[location][peo][
                                  carrier
                                ].manual_rate_values = manual_rate_values;
                                data[location][peo][
                                  carrier
                                ].net_rate_values = manual_rate_values;
                                data[location][peo][
                                  carrier
                                ].manual_premium_values = manual_rate_values;
                                data[location][peo][carrier].state = currState;
                                data[location][peo][carrier].name = location;
                              }
                            }
                          }
                        }

                        let stateTypePromiseDataList = await Promise.all(
                          stateTypePromiseList
                        );
                        for (let stateTypeData of stateTypePromiseDataList) {
                          let { location, peo, carrier, type } = stateTypeData;

                          if (location && peo && carrier && type) {
                            data[location][peo][carrier].masterOrMcp = type;
                          }
                        }

                        if (true) {
                          let prospectAddresses = {};

                          // create the prospect address object
                          for (let stateIndex in childrenLoc) {
                            prospectAddresses[stateIndex] = {
                              stateCode: childrenLoc[stateIndex].state.value,
                              addressId: childrenLoc[stateIndex].number,
                              classCodes: [],
                            };
                            for (let classCodeIndex in childrenLoc[stateIndex]
                              .classCodesInfo) {
                              let classCodeDescArray = childrenLoc[
                                stateIndex
                              ].classCodesInfo[
                                classCodeIndex
                              ].classCodeDescription.value.split(":");

                              let code = classCodeDescArray.shift();
                              let desc = classCodeDescArray.join(":").trim();

                              prospectAddresses[stateIndex].classCodes.push({
                                code,
                                payroll: numeral(
                                  childrenLoc[stateIndex].classCodesInfo[
                                    classCodeIndex
                                  ].payroll.value
                                )
                                  .value()
                                  .toString(),
                                pt: childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].pt.value.trim(),
                                ft: childrenLoc[stateIndex].classCodesInfo[
                                  classCodeIndex
                                ].ft.value.trim(),
                                description: desc,
                              });
                              // quoteFactors.payroll += numeral(
                              //   childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
                              //     .payroll.value
                              // ).value();
                            }
                          }

                          let currentDate = Math.floor(Date.now()).toString();
                          let visitTimestamp = sessionStorage.getItem(
                            "visitTimestamp"
                          );
                          let uuid = sessionStorage.getItem("uuid");
                          if (!uuid) {
                            uuid = uuidv4();
                          }
                          let sortKeyList = [];
                          let address = [];
                          let quoteData;
                          let class_code_object = {};

                          for (let location in eligData) {
                            for (let carrier in eligData[location]) {
                              for (let ele of carrierPeoProgramList) {
                                let place = location
                                  .split("_")
                                  .slice(0, 5)
                                  .join("_");
                                if (
                                  ele.carrier === carrier &&
                                  data[place] &&
                                  ele.peo in data[place] &&
                                  data[place][ele.peo][carrier] &&
                                  data[place][ele.peo][carrier] !== "Dropped"
                                ) {
                                  if (!(place in class_code_object))
                                    class_code_object[place] = {};
                                  if (!(ele.peo in class_code_object[place]))
                                    class_code_object[place][ele.peo] = {};

                                  class_code_object[place][ele.peo][carrier] =
                                    eligData[location][
                                      carrier
                                    ].classCodeEligibility;
                                }
                              }
                            }
                          }

                          let salesforceData;
                          try {
                            salesforceData = JSON.parse(
                              sessionStorage.getItem("salesforceData")
                            );
                          } catch (error) {}

                          for (let place in data) {
                            address.push(place);
                            for (let peo in data[place]) {
                              let tempData = {
                                producerName:
                                  currProspectDetails?.["companyProfile"]?.[
                                    "producer"
                                  ]?.["value"] || "Paul Hughes",
                                producerEmail:
                                  isLoggedIn.attributes.email || " ",
                                producerFax:
                                  isLoggedIn.attributes["custom:fax"] || " ",
                                producerPhone:
                                  isLoggedIn.attributes["custom:phoneNo."] ||
                                  " ",
                                producerMobile:
                                  isLoggedIn.attributes["custom:mobile"] || " ",
                                user_email_id: user_id,
                                uuid_carrier: `${currentDate}@${uuid}_${peo}+${place}`,
                                date: currentDate,
                                visitDate: visitTimestamp,
                                effective_date: moment(
                                  companyProfile.effectiveDate.value
                                ).format("YYYY-MM-DD"),
                                expiration_date: moment(
                                  companyProfile.expectedExpiryDate.value
                                ).format("YYYY-MM-DD"),
                                prospect_addresses: prospectAddresses,
                                phone_number: companyProfile.phoneNumber.value,
                                company_name: companyProfile.companyName.value,
                                fein: companyProfile.fein.value,
                                liability_limitId: "1000-1000-1000",
                                uuid: uuid,
                                peo: `${peo}+${place}`,
                                // carrier_email_id: data[place][peo][carrier].carrier_email,
                                carrier_location_data: data[place][peo],
                                doc_status: "not_uploaded",
                                currProspectDetails,
                                uw_ques: uwQues,
                                industries,
                                details,
                                classCodeEligibility:
                                  class_code_object[place][peo],
                                // data[place][peo][carrier].classCodeEligibility,
                              };

                              if (salesforceData) {
                                tempData.salesforceData = salesforceData;
                              }

                              if (isUwFlow) {
                                tempData.submissionDate = submissionDate;
                                tempData.quoteSubmitted = "true";
                              }

                              sortKeyList.push(tempData.uuid_carrier);
                              carrierUserData.push(tempData);
                            }
                          }

                          quoteData = {
                            uuid,
                            date: currentDate,
                          };

                          sessionStorage.setItem(
                            "amtrustAccountDetails",
                            JSON.stringify(amtrustAccountDetails)
                          );

                          sessionStorage.setItem(
                            "amtrustAccountDetails",
                            JSON.stringify(amtrustAccountDetails)
                          );

                          sessionStorage.setItem(
                            "sortKeyList",
                            JSON.stringify(sortKeyList)
                          );
                          sessionStorage.setItem(
                            "quoteData",
                            JSON.stringify(quoteData)
                          );
                          sessionStorage.setItem(
                            "address",
                            JSON.stringify(address)
                          );

                          let prevIndex = 0;
                          let nextIndex = 5;
                          let uploadData;
                          let postUsersDataPromiseList = [];

                          for (
                            let i = 0;
                            i < Math.floor(carrierUserData.length / 5) + 1;
                            i++
                          ) {
                            uploadData = carrierUserData.slice(
                              prevIndex,
                              nextIndex
                            );
                            if (uploadData.length > 0) {
                              postUsersDataPromiseList.push(
                                axios
                                  .post(
                                    awsUrl + "/api/postUsersData",
                                    uploadData
                                  )
                                  .catch((error) => {
                                    console.log("error in posting data", error);
                                  })
                              );
                            }

                            prevIndex = nextIndex;
                            nextIndex = nextIndex + 5;
                          }

                          await Promise.allSettled(
                            postUsersDataPromiseList
                          ).then(async (res) => {
                            let emailID = isLoggedIn.attributes.email;
                            let userDetails = {
                              user_email_id: user_id,
                              uw_ques: uwQues,
                              status: "quote_generated",
                              uploadTimestamp: currentDate,
                              modifiedBy: emailID,
                              quoteData,
                              address,
                              riskProfile: riskProfile,
                              childrenLoc: childrenLoc,
                              companyProfile: companyProfile,
                              emodStatesData: emodStatesData,
                              peoDetails,
                              carrierList,
                            };

                            if (isUwFlow) {
                              userDetails.submissionDate = submissionDate;
                              userDetails.quoteSubmitted = "true";
                            }

                            await axios
                              .post(
                                awsUrl2 + "/api/userTableData/update",
                                userDetails
                              )
                              .then((res2) => {})
                              .catch((err) => {
                                console.log("Error:", err);
                              });

                            sessionStorage.setItem("formStage", "three");
                            sessionStorage.setItem(
                              "quoteFactors",
                              JSON.stringify(quoteFactors)
                            );
                            $("#loader").css("display", "none");
                            window.location.reload();
                          });
                        } else {
                          sessionStorage.setItem("formStage", "two");
                          $("#loader").css("display", "none");
                          window.location.reload();
                        }
                      }
                    } catch (error) {
                      console.log("error", error);
                      sessionStorage.setItem(
                        "quoteFactors",
                        JSON.stringify(quoteFactors)
                      );
                      $("#loader").css("display", "none");
                      window.location.reload();
                    }
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
            };

            fetchData(id);

            let _sortKeyList = sessionStorage.getItem("sortKeyList");

            await axios.post(awsUrl + "/api/updateStatusDetails", {
              button_name: "get_quote",
              user_id: loggedIn_emailID,
              user_email_id: user_id,
              sortKeyList: JSON.parse(_sortKeyList),
            });
          } else {
            console.log("no carriers available");
            $("#loader").css("display", "none");
          }
        })
        .catch((err) => {
          console.log("error in eligibility api", err);
          $("#loader").css("display", "none");
        });
    }
  } catch (error) {
    console.log("error", error);
    $("#loader").css("display", "none");
  }
}

export function updateQuesList(e, key, value) {
  let { quesList } = this.state;
  let index = e.target.id.split("-")[1];
  quesList[index][key] = value;
  this.setState({ quesList });
}

export function updateAmtrustQuesList(e, key, value) {
  let { amtrustQuesList } = this.state;
  let index = e.target.id.split("-")[1];
  amtrustQuesList[index][key] = value;
  this.setState({ amtrustQuesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach((question) => {
    quesListAdd[index] = question;
    index++;
  });
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {};
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}

import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2 } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import Loader from "../common/Loader";
import Header from "../common/Header";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import SideTab from "../../newcomponent/common/SideTab";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

let isMobile = window.innerWidth < 900;
let ismobile_font = window.innerWidth < 1600;
let {
  carrierPeoProgramList,
  programMapping,
} = require("../../utils/carrierList.json");

let downloadFile = require("../../images/downloadFile.png");

class DashboardRater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      fullscreenLoading: false,
    };
  }
  componentDidMount = () => {
    sessionStorage.clear();
    this.updateDisplay();
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  onClickName = (rowData) => {
    if (
      rowData.newRatesModel &&
      rowData.emodStatesData &&
      rowData.effectiveDate &&
      rowData.selectedProgram &&
      rowData.selectedPeo &&
      rowData.selectedCarrierList
    ) {
      sessionStorage.setItem(
        "selectedProgram",
        JSON.stringify(rowData.selectedProgram)
      );
      sessionStorage.setItem(
        "newRatesModel",
        JSON.stringify(rowData.newRatesModel)
      );
      sessionStorage.setItem(
        "emodStatesData",
        JSON.stringify(rowData.emodStatesData)
      );
      sessionStorage.setItem("effectiveDate", rowData.effectiveDate);
      sessionStorage.setItem("name", rowData.id);
      sessionStorage.setItem("selectedPeo", rowData.selectedPeoInternal);
      sessionStorage.setItem(
        "selectedCarrierList",
        JSON.stringify(rowData.selectedCarrierList)
      );
      if (rowData.carrierEligibility)
        sessionStorage.setItem(
          "carrierEligibility",
          JSON.stringify(rowData.carrierEligibility)
        );
      window.location.href = "/rates";
    } else {
      console.log("Incorrect Data");
    }
  };
  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.84rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.8rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.76rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  setTableData = () => {
    let { rowDataList, isLoading } = this.state;

    let columns = {
      tab1: [
        {
          title: "Effective Date",
          field: "effectiveDate",
          defaultSort: "desc",
          render: (rowData) => {
            let visitDate = moment(rowData.effectiveDate, "YYYY-MM-DD").format(
              "M/D/YY"
            );
            return <span>{visitDate}</span>;
          },
          headerStyle: {
            minWidth: "150px",

            minWidth: "160px",
          },
          cellStyle: {
            padding: "2px 0px 2px 12px",
            width: "150px",

            minWidth: "150px",
          },
        },
        {
          title: "Distributing Partner",
          field: "selectedPeo",
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "190px",
            width: "190px",
            maxWidth: "160px",
            textTransform: "capitalize",
          },
          render: (rowData) => {
            return rowData.selectedPeo;
          },
        },
        {
          title: "Name of the Client",
          field: "id",
          headerStyle: {
            minWidth: "220px",
          },
          cellStyle: {
            padding: "2px 0px 2px 6px",

            minWidth: "220px",
          },

          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <div
                  onClick={() => this.onClickName(rowData)}
                  className="company-name"
                >
                  {this.camelCase(rowData.id)}
                </div>
              </span>
            </Clamp>
          ),
        },
        {
          title: "State",
          field: "states",
          cellStyle: {
            padding: "2px 0px 10px 6px",
            minWidth: "150px",
          },
          render: (rowData) => {
            return <Clamp lines={2}>{rowData.states}</Clamp>;
          },
        },
        {
          title: "Carrier",
          field: "carriers",

          cellStyle: {
            padding: "2px 0px 2px 6px",
            width: "200px",

            minWidth: "190px",
          },
          render: (rowData) => {
            let demoCarrierMap = {
              "Key Risk": "Carrier A",
              UWIC: "Carrier B",
            };
            let carrier = rowData.carriers;
            if (rowData.selectedPeo === "demo") {
              if (carrier in demoCarrierMap) {
                carrier = demoCarrierMap[carrier];
              }
            }
            return <Clamp lines={2}>{carrier} </Clamp>;
          },
        },
        {
          title: "Download",
          field: "downloadData",
          filtering: false,
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "55px",
            textAlign: "center",
          },
          headerStyle: {
            textAlign: "right",
            padding: "6px 2px 4px 14px",
          },
          render: (rowData) => {
            return (
              <CSVLink
                filename={"Libertate Rater Data.csv"}
                data={rowData.downloadData || {}}
              >
                <img src={downloadFile} width="35px" height="35px" />
              </CSVLink>
            );
          },
        },
        {
          title: "",
          field: "quoteTool",
          cellStyle: {
            padding: "2px 0px 12px 6px",
            minWidth: "100px",
            align: "left",
          },

          render: (rowData) => {
            return (
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-warning btn-sm onClickQuoteToolBtn "
                  onClick={() => {
                    this.handleOnClickQuoteToolBtn(rowData);
                  }}
                >
                  Quote Tool
                </button>
              </div>
            );
          },
        },
      ],
    };

    return (
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        data={rowDataList}
        columns={columns["tab1"]}
        options={{
          paginationPosition: "both",
          padding: "dense",
          showTitle: false,
          thirdSortClick: false,
          pageSize: 10,
          pageSizeOptions: [25, 50, 75, 100],
          headerStyle: {
            fontSize: ismobile_font ? this.fontfunciton() : "1rem",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: "6px 15px 6px 10px",
            fontFamily: "Montserrat",
          },
          rowStyle: {
            fontSize: ismobile_font ? this.fontfunciton() : "0.95rem",
            color: "#000",
            fontFamily: "Montserrat",
          },
          searchFieldStyle: {
            fontSize: "0.95rem",
          },
          filterCellStyle: {
            padding: "5px 15px 5px 4px",
            fontFamily: "Montserrat",
          },
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
              }
              labelDisplayedRows={(row) => (
                <div style={{ fontSize: 12 }}>
                  {props.labelDisplayedRows(row)}
                </div>
              )}
              SelectProps={{
                style: {
                  fontSize: 12,
                },
              }}
            />
          ),
        }}
      />
    );
  };

  updateDisplay = async () => {
    let sessionData = await Auth.currentAuthenticatedUser();
    // this.setState({ isLoading: false });
    let _revCarrMap = {};
    let _actualPeoName = {};

    for (let data of carrierPeoProgramList) {
      _revCarrMap[data.carrier] = data.actualCarrierName;
      _actualPeoName[data.peo] = data.displayPeoName;
    }

    if (sessionData) {
      let userType = sessionData.attributes["custom:user_type"];
      let req_body = {
        first_time_loading: "true",
      };

      let userDataList = [];
      let last_key;

      if (userType === "peo") {
        let email = sessionData.attributes.email;
        let user_peo = email.split("@")[0];
        req_body[userType] =
          user_peo === "jgregory"
            ? "gregory"
            : user_peo === "galactichr"
            ? "galactic"
            : user_peo;
      }
      await new Promise((resolve, reject) => {
        axios
          .post(awsUrl2 + "/api/raterTableData/getAll", req_body)
          .then((res) => {
            userDataList = res.data;
            last_key = res.old_key;

            for (let userdata of userDataList) {
              userdata.states = "";
              userdata.carriers = "";

              userdata.downloadData = "";

              userdata.selectedPeoInternal = userdata.selectedPeo;

              if (userdata.selectedPeo in _actualPeoName) {
                userdata.selectedPeo = _actualPeoName[userdata.selectedPeo];
              }

              try {
                ({
                  response: userdata.downloadData,
                  states: userdata.states,
                } = this.prepareDownloadData(
                  userdata.newRatesModel,
                  userdata.selectedPeo
                ));
              } catch (error) {
                console.log("inside loop", error);
              }

              let carr_len = userdata?.selectedCarrierList?.length;
              if (carr_len) {
                if (carr_len === 1) {
                  userdata.carriers =
                    userdata.selectedCarrierList[0] &&
                    userdata.selectedCarrierList[0] in _revCarrMap
                      ? _revCarrMap[userdata.selectedCarrierList[0]]
                      : "";
                } else if (
                  carr_len === 2 &&
                  userdata?.selectedCarrierList.includes("select_all")
                ) {
                  userdata.carriers =
                    userdata.selectedCarrierList[1] &&
                    userdata.selectedCarrierList[1] in _revCarrMap
                      ? _revCarrMap[userdata.selectedCarrierList[1]]
                      : "";
                } else {
                  userdata.carriers = "Multiple";
                }
              }
            }

            this.setState({ rowDataList: userDataList });
            resolve();
          })
          .catch((error) => {
            console.log("error", error);
            reject();
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      });

      do {
        if (last_key) {
          req_body["lastEvaluatedKey"] = last_key;
        }

        delete req_body["first_time_loading"];

        await new Promise((resolve, reject) => {
          axios
            .post(awsUrl2 + "/api/raterTableData/getAll", req_body)
            .then((res) => {
              userDataList = res.data;
              last_key = res.old_key;

              for (let userdata of userDataList) {
                userdata.states = "";
                userdata.carriers = "";

                userdata.downloadData = "";

                userdata.selectedPeoInternal = userdata.selectedPeo;

                if (userdata.selectedPeo in _actualPeoName) {
                  userdata.selectedPeo = _actualPeoName[userdata.selectedPeo];
                }

                try {
                  ({
                    response: userdata.downloadData,
                    states: userdata.states,
                  } = this.prepareDownloadData(
                    userdata.newRatesModel,
                    userdata.selectedPeo
                  ));
                } catch (error) {
                  console.log("inside loop", error);
                }

                let carr_len = userdata?.selectedCarrierList?.length;
                if (carr_len) {
                  if (carr_len === 1) {
                    userdata.carriers =
                      userdata.selectedCarrierList[0] &&
                      userdata.selectedCarrierList[0] in _revCarrMap
                        ? _revCarrMap[userdata.selectedCarrierList[0]]
                        : "";
                  } else if (
                    carr_len === 2 &&
                    userdata?.selectedCarrierList.includes("select_all")
                  ) {
                    userdata.carriers =
                      userdata.selectedCarrierList[1] &&
                      userdata.selectedCarrierList[1] in _revCarrMap
                        ? _revCarrMap[userdata.selectedCarrierList[1]]
                        : "";
                  } else {
                    userdata.carriers = "Multiple";
                  }
                }
              }

              this.setState((prevState) => {
                const newRowDataList = [
                  ...prevState.rowDataList,
                  ...userDataList,
                ];
                return { rowDataList: newRowDataList };
              });
              resolve();
            })
            .catch((error) => {
              console.log("error", error);
              reject();
            })
            .finally(() => {
              this.setState({ isLoading: false });
            });
        });
      } while (last_key);
    }
  };

  //function to return the maximum key value in the childrenLoc
  childrenLocMaxKey = (object) => {
    let max = 0;
    for (let key in object) {
      max = Math.max(max, Number(key));
    }
    return Number(max + 1).toString();
  };

  //function to return class code info empty object
  returnClassCodeInfo = (ccDesc = "") => {
    return {
      classCodeDescription: {
        error: false,
        value: ccDesc,
      },
      ft: {
        value: "",
      },
      payroll: {
        value: "",
      },
      pt: {
        value: "",
      },
    };
  };

  //fuction to create the currProspect
  handleOnClickQuoteToolBtn = ({
    newRatesModel,
    emodStatesData,
    selectedPeo,
    selectedPeoInternal,
    selectedCarrierList,
  }) => {
    try {
      this.setState({ fullscreenLoading: true });
      let childrenLoc = {};
      let key = 0;

      //map to store state and its first occured index (in childrenLoc)
      let stateKeyMap = {};

      //empty company profile object
      let companyProfile = {
        companyName: {},
        descriptionOfOperations: {},
        firstName: {},
        lastName: {},
        yearsInBusiness: {},
        phoneNumber: {},
        entityType: {},
        expectedStartDate: { value: moment() },
        effectiveDate: { value: moment() },
        fein: {},
        street1: {},
        zipCode1: {},
        cityName1: {},
        state1: {},
        street2: {},
        zipCode2: {},
        cityName2: {},
        state2: {},
      };

      //loop over ratesModel object to create FormCompPro compatible childreLoc object
      newRatesModel.states.forEach((stateObject) => {
        //state exists in stateKeyMap then append a new classCode inside classCodeInfo
        if (stateObject.state.value in stateKeyMap) {
          let availableKey = stateKeyMap[stateObject.state.value];

          let currentChildrenLoc = childrenLoc[availableKey];

          let currentChildrenLocClassCodeInfo =
            currentChildrenLoc.classCodesInfo;

          let currentMaxKey = this.childrenLocMaxKey(
            currentChildrenLocClassCodeInfo
          );

          childrenLoc[availableKey].classCodesInfo[
            currentMaxKey
          ] = this.returnClassCodeInfo(
            stateObject?.classCodes[0]?.classCode?.label
          );
        }
        //create new state in the stateKeyMap and childrenLoc
        else if (stateObject.state.label !== "--Select--") {
          stateKeyMap[stateObject.state.value] = Number(key).toString();

          childrenLoc[Number(key).toString()] = {
            cityName: {
              value: "",
            },
            classCodesInfo: {},
            key: Math.random(),
            number: key,
            state: {
              value: stateObject.state.label,
            },
            street: {
              value: "",
            },
            suta: {},
            zipCode: {
              value: "",
            },
          };

          childrenLoc[Number(key).toString()].classCodesInfo[
            "0"
          ] = this.returnClassCodeInfo(
            stateObject?.classCodes[0]?.classCode?.label
          );
          key++;
        }
      });

      //if childrenLoc object is created succesfully then create currProspect route to "/quote"
      if (childrenLoc && Object.keys(childrenLoc).length > 0) {
        // console.log("childrenO: ", childrenLoc);

        let peoDetails = { selectedPeo: "" };
        if (selectedPeoInternal) {
          peoDetails = { selectedPeo: selectedPeoInternal };
        }

        let carrierList = { value: [], error: false };
        if (selectedCarrierList) {
          let _carrierList = [];

          let carrierSet = new Set();

          let hasCarrier = (list, carr) => {
            // console.log(list);
            for (let ele of list) {
              if (ele === carr) return true;
            }
            return false;
          };

          for (let data of carrierPeoProgramList) {
            if (data.devRemoved && process.env.REACT_APP_MODE === "dev")
              continue;
            if (data.prodRemoved && process.env.REACT_APP_MODE === "prod")
              continue;
            if (data.disableCarrier) continue;

            if (
              hasCarrier(selectedCarrierList, data.carrier) &&
              !carrierSet.has(data.carrier)
            ) {
              _carrierList.push({
                label: data.actualCarrierName,
                value: data.carrier,
              });
              carrierSet.add(data.carrier);
            }
          }
          carrierList.value = _carrierList;
        }

        sessionStorage.clear();
        sessionStorage.setItem(
          "currProspect",
          JSON.stringify({
            companyProfile,
            childrenLoc,
            emodStatesData,
            peoDetails,
            carrierList,
          })
        );
        this.setState({ fullscreenLoading: false });
        window.location.href = "/quote";
      } else {
        console.log("error in generating quote data");
        // setQuoteToolError("Error");
        this.setState({ fullscreenLoading: false });
      }
    } catch (error) {
      console.log("error ", error);
      // setQuoteToolError("Error");
      this.setState({ fullscreenLoading: false });
    }
  };

  prepareDownloadData = (newRatesModel, selectedPeo) => {
    let response = [],
      stateList = [];
    newRatesModel.states.forEach((stateData) => {
      let rowData = {};
      let state = stateData.state.label;
      if (!stateList.includes(state)) stateList.push(state);
      stateData.classCodes.forEach((classCodeData) => {
        let classCode = classCodeData.classCode.label.split(":")[0].trim();
        let description = classCodeData.classCode.label
          .split(":")
          .slice(1)
          .join(":")
          .trim();
        rowData = {
          State: state,
          "Class Code": classCode,
          Description: description,
        };
        classCodeData.newRatesResponse.forEach((programData) => {
          let program = programData.program;
          let manualRate = programData.manualRate;
          let netRate = programData.netRate;
          if (program !== "N/A") {
            rowData[
              `${
                selectedPeo === "demo"
                  ? `Demo-${programMapping[program]?.carrier}`
                  : program
              } - Manual Rate`
            ] = manualRate;
            rowData[
              `${
                selectedPeo === "demo"
                  ? `Demo-${programMapping[program]?.carrier}`
                  : program
              } - Net Rate`
            ] = netRate;
          }
        });
        response.push(rowData);
      });
    });

    return {
      response,
      states:
        `${stateList.splice(0, 4).join(", ")}${
          stateList.length > 4 ? "..." : ""
        }` || "",
    };
  };

  render() {
    return (
      <div className="dashboard-container dashboard-rater">
        <div
          className="d-flex bg-light"
          style={{ borderBottomStyle: "groove" }}
        >
          <Header />
        </div>
        <div className="position-relative">
          {isMobile ? <SideTab /> : " "}
          <div className="tabs  rater_dashboard">
            <div className="d-flex justify-content-center mb-3 text-center mt-3 mb-2">
              <h2>
                <b>{"Dashboard"}</b>
              </h2>
            </div>
            <div>{this.setTableData()}</div>
          </div>
        </div>

        <Loader />
      </div>
    );
  }
}

export default DashboardRater;

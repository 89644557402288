import React, { Component } from "react";
import Allques from "../subcompo/sections-quote/Allques";
import { quesList } from "../../utils/ques_list";
import $ from "jquery";
import NewProgress from "../../newcomponent/common/NewProgress";
import { awsUrl2 } from "../../config";
import axios from "axios";
import Header from "../common/Header";
import { amtrustResponse } from "../../utils/form_ques";
import {
  saveInUserTable,
  saveInUserstatusTable,
} from "../../utils/form_compPro";
import Loader from "../common/Loader";
import LoadingOverlay from "react-loading-overlay";

class AmtrustUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: quesList,
      amtrustQuesList: [],
      dataList: [],
      responseMessage: "",
      loading: true,
      quesloading: false,
    };
    this.submitQuesAmtrust = this.submitQuesAmtrust.bind(this);
  }

  setUWQues = () => {
    let { amtrustQuesList } = this.state;
    for (let quesIndex in amtrustQuesList) {
      try {
        if (amtrustQuesList[quesIndex].resp === "Y") {
          amtrustQuesList[quesIndex].response = true;
        } else {
          amtrustQuesList[quesIndex].response = false;
        }
      } catch (error) {}
    }
    this.setState({ amtrustQuesList });
  };

  createQuestionsData = () => {
    let { amtrustQuesList, dataList } = JSON.parse(JSON.stringify(this.state));
    for (let question of [...amtrustQuesList]) {
      if (question.response === true) {
        question.response = "Y";
      } else if (question.response === false) {
        question.response = "N";
      }
    }

    return { amtrustQuesList };
  };

  async componentDidMount() {
    const { dataList } = this.state;
    $("html, body").animate({ scrollTop: 0 }, 250);

    // debugger
    let currProspect;
    currProspect = sessionStorage.getItem("currProspect");
    try {
      currProspect = JSON.parse(currProspect);
    } catch (error) {}

    try {
      let amtrustAccountDetails = sessionStorage.getItem(
        "amtrustAccountDetails"
      );
      // debugger;

      try {
        amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
      } catch (error) {}

      if (amtrustAccountDetails && amtrustAccountDetails?.QuoteId) {
        let amtrustQuesList = [];
        let quoteId = amtrustAccountDetails.QuoteId;

        let amtrustTriggerResponse = await axios.get(
          awsUrl2 + `/api/triggerAmtrustGetQuestions/${quoteId}`
        );

        let uuid;
        if (amtrustTriggerResponse.data.uuid) {
          uuid = amtrustTriggerResponse.data.uuid;
          console.log("amtrustTriggerResponse for question uuid--->", uuid);
        } else {
          throw "error in amtrust create quote trigger";
        }

        let amtrustQuestionsResponse;
        do {
          amtrustQuestionsResponse = await amtrustResponse(
            uuid,
            "getQuestions"
          );
          if (amtrustQuestionsResponse === "error") {
            throw "error in amtrust get questions";
          }
        } while (amtrustQuestionsResponse === "processing");

        if (amtrustQuestionsResponse.StatusCode === 200) {
          let amtrustQuesText = [];
          console.log("++", amtrustQuestionsResponse.Data);

          for (let question of amtrustQuestionsResponse.Data) {
            if (!amtrustQuesText.includes(question.Question)) {
              let response;
              amtrustQuesText.push(question.Question);
              if (question.Answer && question.Answer.toLowerCase() === "yes") {
                response = true;
              } else if (
                question.Answer &&
                question.Answer.toLowerCase() === "no"
              ) {
                response = false;
              }

              amtrustQuesList.push({
                name: question.Id,
                ques: question.Question,
                response,
              });
            }
          }
          this.setState({ amtrustQuesList });
          this.setState({ loading: false });
        }
      } else if (currProspect.amtrustQuesList) {
        this.setState({ amtrustQuesList: currProspect.amtrustQuesList });
      } else {
        console.log("Amtrust quote not generated");
      }
    } catch (error) {
      console.log("error while setting amtrust questions", error);
    }
  }

  updateQuesList = (e, key, value) => {
    let { amtrustQuesList } = this.state;
    let index = e.target.id.split("-")[1];
    amtrustQuesList[index][key] = value;
    this.setState({ amtrustQuesList });
  };

  setQuestions = (amtrustQuesList) => {
    let response = {};
    for (let question of amtrustQuesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
        remarks: question.remarks,
      };
    }

    return response;
  };

  submitQuesAmtrust = async (amtrustQuesList) => {
    // debugger;
    this.setState({ quesloading: true });
    let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");
    console.log(amtrustAccountDetails, "got an Amtrust Id");
    try {
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    } catch (error) {
      amtrustAccountDetails = {};
    }
    // saving the amtrust uw question response  to amtrust
    if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
      try {
        let amtrustSaveQuestionsRequestBody = [];
        for (let question of amtrustQuesList) {
          amtrustSaveQuestionsRequestBody.push({
            QuestionId: question.name,
            AnswerValue: question.response ? "yes" : "no",
          });
        }

        let amtrustSaveQuestionsTriggerResponse = await axios.post(
          awsUrl2 +
            `/api/triggerAmtrustSaveQuestions/${amtrustAccountDetails.QuoteId}`,
          amtrustSaveQuestionsRequestBody
        );
        console.log(
          "amtrustSaveQuestionsTriggerResponse",
          amtrustSaveQuestionsTriggerResponse
        );

        let saveQuestionsUuid;
        if (
          amtrustSaveQuestionsTriggerResponse.data.uuid
          // &&amtrustSavePaymentPlansTriggerResponse.data.uuid
        ) {
          saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
          console.log("saveQuestionsUuid", saveQuestionsUuid);
          // savePaymentPlansUuid =
          //   amtrustSavePaymentPlansTriggerResponse.data.uuid;
        } else {
          throw "error in amtrust create quote trigger";
        }

        let amtrustQuestionsResponse;
        do {
          let promiseList = [
            amtrustResponse(saveQuestionsUuid, "saveQuestions"),
          ];

          await Promise.all(promiseList)
            .then((res) => {
              console.log("++res", res);
              amtrustQuestionsResponse = res[0];
              if (res !== "") {
                this.setState({ responseMessage: res[0].Message });
              }
              // amtrustSavePaymentPlansResponse = res[1];
            })
            .catch((err) => {
              console.log("error", err);
              throw "error in amtrust quote creation";
            });
          if (
            amtrustQuestionsResponse === "error"
            // ||amtrustSavePaymentPlansResponse === "error"
          ) {
            throw "error in amtrust quote generation";
          }
        } while (
          amtrustQuestionsResponse === "processing"
          // ||amtrustSavePaymentPlansResponse === "processing"
        );
      } catch (error) {
        console.log("error in fetching amtrust quote details response", error);
      }
    }

    // get amtrust quote details to check the quote Eligibity so that we can bind the policy or not
    // debugger
    let amtrustQuoteDetailsResponse = {};
    if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
      try {
        let amtrustQuoteDetailsTriggerResponse = await axios.get(
          awsUrl2 +
            `/api/triggerAmtrustGetQuoteDetails/${amtrustAccountDetails.QuoteId}`
        );
        console.log(amtrustQuoteDetailsTriggerResponse);

        let quoteDetailsUuid;
        if (amtrustQuoteDetailsTriggerResponse.data.uuid) {
          quoteDetailsUuid = amtrustQuoteDetailsTriggerResponse.data.uuid;
        } else {
          throw "error in amtrust create quote trigger";
        }

        do {
          amtrustQuoteDetailsResponse = await amtrustResponse(
            quoteDetailsUuid,
            "getQuoteDetails"
          );
          console.log(
            "amtrustQuoteDetailsResponse--->",
            amtrustQuoteDetailsResponse
          );
          let amtrust_eligibility;
          if (amtrustQuoteDetailsResponse) {
            amtrust_eligibility = amtrustQuoteDetailsResponse?.Eligibility;
            // console.log("Amtrusteligibility----->", amtrust_eligibility);
            if (amtrust_eligibility) {
              let user_id = sessionStorage.getItem("user_id");
              let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
              let address = JSON.parse(sessionStorage.getItem("address"));
              let currProspectDetails = JSON.parse(
                sessionStorage.getItem("currProspect")
              );
              let req_body_userstatus = {
                user_email_id: user_id,
                uuid: quoteData.uuid,
                timestamp: quoteData.date,
                address: address,
                peo:
                  currProspectDetails.peoDetails.selectedPeo === "demo"
                    ? "gms"
                    : currProspectDetails.peoDetails.selectedPeo,
                amtrustEligibility: amtrust_eligibility,
              };
              let req_body_users = {
                user_email_id: user_id,
                amtrustEligibility: amtrust_eligibility,
              };
              let result1 = saveInUserstatusTable(req_body_userstatus);
              let result2 = saveInUserTable(req_body_users);

              Promise.all([result1, result2])
                .then((res) => {
                  console.log("Data Insterted Sucessfully");
                  this.setState({ quesloading: false });
                  sessionStorage.setItem(
                    "AmtrustEligibility",
                    JSON.stringify(amtrust_eligibility)
                  );
                  this.props.updateShowState(false);
                  window.location.reload();
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
          if (amtrustQuoteDetailsResponse === "error") {
            throw "error in amtrust get net rates";
          }
        } while (amtrustQuoteDetailsResponse === "processing");
      } catch (error) {
        console.log("error in fetching amtrust quote details response", error);
      }
    }

    console.log(amtrustQuoteDetailsResponse, "amtrustQuoteDetailsResponse");
  };

  render() {
    const {
      incompleteQuestions,
      amtrustQuesList,
      responseMessage,
      loading,
      quesloading,
    } = this.state;
    const { updatePopUpData } = this.props;
    return (
      <div
        id="undrQues"
        className="container-fluid per98"
        style={{ background: "#e2f2ff", height: "auto" }}
      >
        <LoadingOverlay active={loading} spinner>
          <div id="underWritingQues">
            <form>
              <div
                className="table-responsive table-undrques-container"
                style={{ fontSize: "12px" }}
              >
                <Allques
                  quesList={amtrustQuesList}
                  setUWQues={this.setUWQues}
                  updateQuesList={this.updateQuesList}
                />
              </div>
              {incompleteQuestions && (
                <div className="errMsg text-center mb-4">
                  Please answer all the questions!
                </div>
              )}
            </form>
            <div className="row">
              <div
                className="col-12 text-center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <button
                  type="button"
                  className="btn btn-warning btn-lg btn-warning-shadow btnSubmits"
                  onClick={() => {
                    this.submitQuesAmtrust(amtrustQuesList);
                  }}
                >
                  Get Quote
                </button>
                <LoadingOverlay active={quesloading} spinner></LoadingOverlay>
              </div>

              {responseMessage && (
                <div className="responseMessage-msg">
                  <h4>
                    <b>{responseMessage}</b>
                  </h4>
                </div>
              )}
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

export default AmtrustUnderQues;

//component for description code mapping
import React, { Component } from "react";
import { awsUrl2 } from "../../config";
import { getHeader } from "../../utils/common";
import AsyncSelect from "react-select/async";
import { Form } from "react-bootstrap";
import debounce from "../../utils/optimize_api";

const axios = require("axios");
class DescriptionCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOptionsMessage: "Enter 3 characters to start",
      key: Math.random(),
      initialDescApiCalled: false,
      placeholderText: "Enter 3 characters",
    };
  }

  componentDidUpdate = () => {
    const {
      addressBlockNo,
      addressChildNo,
      classCodesInfo,
      state,
    } = this.props;
    const { updateLocationError } = this.props;
    if (
      JSON.stringify(classCodesInfo) !==
      JSON.stringify(this.state.classCodesInfo)
    ) {
      let description;
      let returnList = [];
      if (
        classCodesInfo &&
        classCodesInfo[addressChildNo].classCodeDescription &&
        classCodesInfo[addressChildNo].classCodeDescription.value
      ) {
        description = classCodesInfo[addressChildNo].classCodeDescription.value;
        let desc = description?.split(":")[1]?.trim();
        this.setState({ classCodesInfo });
        if (
          desc === "no description found" &&
          !this.state.initialDescApiCalled
        ) {
          this.setState({ initialDescApiCalled: true });
          // const header = await getHeader();
          let value = description.split(":")[0];
          axios
            .get(awsUrl2 + `/api/getCcodes/${state}/${value}`)
            .then((res) => {
              let dbObjsList = res.data.data;
              if (dbObjsList.length === 0) {
                this.setState({ noOptionsMessage: "No data found" });
              }
              dbObjsList.forEach((element) => {
                returnList.push({
                  value: `${element.class_code}: ${element.description}`,
                  label: `${element.class_code}: ${element.description.trim()}`,
                });
              });
              this.setState({ defaultOptionsList: returnList });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              updateLocationError(
                addressBlockNo,
                addressChildNo,
                "classCodeDescription",
                true
              );
            });
        } else if (classCodesInfo[addressChildNo]?.fromAcord) {
          let value = description.split(":")[0];
          axios
            .get(awsUrl2 + `/api/getCcodes/${state}/${value}`)
            .then((res) => {
              let dbObjsList = res.data.data;
              if (dbObjsList.length === 0) {
                this.setState({ noOptionsMessage: "No data found" });
              }
              dbObjsList.forEach((element, index) => {
                let option = {
                  value: `${element.class_code}: ${element.description}`,
                  label: `${element.class_code}: ${element.description.trim()}`,
                };
                if (index === 0) {
                  this.handleSelectChange(option, false);
                }
                returnList.push(option);
              });
              this.setState({ defaultOptionsList: returnList });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
    if (this.props.state !== this.state.state) {
      this.setState({
        key: Math.random(),
        state: this.props.state,
        noOptionsMessage: "Enter 3 characters to start",
      });
    }
  };

  handleSelectChange = (selectedOption, deleteAcordFlag = true) => {
    const {
      addressBlockNo,
      addressChildNo,
      handleMinMax,
      updateClassCodesInfo,
    } = this.props;

    updateClassCodesInfo(
      addressBlockNo,
      addressChildNo,
      "classCodeDescription",
      selectedOption.value,
      deleteAcordFlag
    );
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ prevDescription: selectedOption.value });
  };

  loadOptions = async (value, callback) => {
    let minLength = isNaN(value) ? 3 : 1;
    if (value.length >= minLength) {
      let returnList = [];
      const { state } = this.props;
      // const state = "fl";
      console.log(awsUrl2 + `/api/getCcodes/${state}/${value}`);
      await axios
        .get(awsUrl2 + `/api/getCcodes/${state}/${value}`)
        .then((res) => {
          let dbObjsList = res.data.data;
          if (dbObjsList.length === 0) {
            this.setState({ noOptionsMessage: "No data found" });
          }
          dbObjsList.forEach((element) => {
            returnList.push({
              value: `${element.class_code}: ${element.description}`,
              label: `${element.class_code}: ${element.description.trim()}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return callback(returnList);
    } else {
      this.setState({ noOptionsMessage: "Enter 3 characters to start" });
    }
  };

  customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      border: state.isFocused ? "2px solid black" : "none",
      background: "transparent",
      borderRadius: "4px",
      boxShadow: "0.5px 1px 4px grey",
      "&:hover": {
        border: state.isFocused ? "2px solid black" : "none",
      },
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  customStylesErr = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px",
      border: "1px solid red",
      background: "transparent",
      borderRadius: "4px",
      boxShadow: "none",
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };
  conditional_Render_worker = (value) => {
    let style = {};
    // this.setState({ screenWidth: window.screen.width });

    if (window.screen.width > 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };
    }

    if (value === "WC" && window.screen.width > 993) {
      style = { width: "43%", paddingInline: "15px" };
      return style;
    }
  };
  render() {
    const {
      noOptionsMessage,
      classCodesInfo,
      key,
      defaultOptionsList,
      placeholderText,
      prevDescription,
    } = this.state;
    const { addressBlockNo, addressChildNo } = this.props;
    let descValue;
    let dropDownValue;
    let description;
    let errorMessage = "Invalid class code. Please check.";
    if (
      classCodesInfo &&
      classCodesInfo[addressChildNo].classCodeDescription &&
      classCodesInfo[addressChildNo].classCodeDescription.value
    ) {
      description = classCodesInfo[addressChildNo].classCodeDescription.value;
      let code = description?.split(":")[0]?.trim();
      let desc = description
        ?.split(":")
        .slice(1)
        .join(":")
        ?.trim();
      if (desc === "no description found") {
        errorMessage = "Please select the description.";
        descValue = [
          {
            label: code,
            value: code,
          },
        ];
        dropDownValue = defaultOptionsList;
      } else if (classCodesInfo[addressChildNo]?.fromAcord) {
        descValue = [
          {
            label: description,
            value: description,
          },
        ];
        dropDownValue = defaultOptionsList;
      } else {
        descValue = [
          {
            label: description,
            value: description,
          },
        ];
        dropDownValue = descValue;
      }
    }

    return (
      <div
        style={this.conditional_Render_worker("WC")}
        className="worker_comp_code_input"
        id={`class-code-desc-${addressBlockNo}${addressChildNo}`}
      >
        <Form.Group
          style={
            classCodesInfo &&
            classCodesInfo[addressChildNo].classCodeDescription &&
            classCodesInfo[addressChildNo].classCodeDescription.error
              ? { marginBottom: 0 }
              : {}
          }
        >
          <label title="Worker's Compensation Code" className="mandatory">
            <b className="font-family-montserrat-semi-bold">
              Workers' Comp Class Code or Description
            </b>
          </label>
          <div
            title={`${
              description ? description : "Enter 3 characters to start"
            }`}
          >
            <AsyncSelect
              key={key}
              value={descValue ? descValue : []}
              className="class-desc-select"
              classNamePrefix="class-desc-select"
              cacheOptions
              defaultOptions={dropDownValue ? dropDownValue : []}
              onClick={debounce(this.loadOptions)}
              loadOptions={debounce(this.loadOptions)}
              noOptionsMessage={() =>
                classCodesInfo?.[addressChildNo]?.fromAcord &&
                noOptionsMessage !== "No data found"
                  ? "Loading..."
                  : noOptionsMessage
              }
              onFocus={() => {
                this.setState({ placeholderText: "" });
                const {
                  addressBlockNo,
                  addressChildNo,
                  handleMinMax,
                  updateClassCodesInfo,
                } = this.props;
                const { classCodesInfo } = this.state;

                this.setState({ prevDescription: description });

                if (classCodesInfo?.[addressChildNo]?.fromAcord) {
                  updateClassCodesInfo(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    classCodesInfo[
                      addressChildNo
                    ].classCodeDescription.value?.split(":")?.[0]
                  );
                } else {
                  updateClassCodesInfo(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    undefined
                  );
                }
                handleMinMax(addressBlockNo, addressChildNo);
              }}
              onBlur={() => {
                this.setState({ placeholderText: "Enter 3 characters" });
                if (!prevDescription) return;
                let desc = prevDescription.split(":")[1].trim();
                if (desc !== "no description found") {
                  const {
                    addressBlockNo,
                    addressChildNo,
                    handleMinMax,
                    updateClassCodesInfo,
                  } = this.props;

                  updateClassCodesInfo(
                    addressBlockNo,
                    addressChildNo,
                    "classCodeDescription",
                    prevDescription
                  );
                  handleMinMax(addressBlockNo, addressChildNo);
                }
              }}
              onChange={(selectedOption) =>
                this.handleSelectChange(selectedOption)
              }
              placeholder={placeholderText}
              styles={
                classCodesInfo &&
                classCodesInfo[addressChildNo].classCodeDescription &&
                classCodesInfo[addressChildNo].classCodeDescription.error
                  ? this.customStylesErr
                  : this.customStyles
              }
            />
          </div>
        </Form.Group>
        {classCodesInfo &&
          classCodesInfo[addressChildNo].classCodeDescription &&
          classCodesInfo[addressChildNo].classCodeDescription.error && (
            <div style={{ color: "red", fontSize: "11px" }}>{errorMessage}</div>
          )}
      </div>
    );
  }
}

export default DescriptionCode;

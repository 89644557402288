import React from "react";
const ToggleSwitch = ({ id, toggleSwitchCheck, toggleSwitchHandler }) => {
  return (
    <div className={"toggle-switch"}>
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        id={id}
        defaultChecked={toggleSwitchCheck}
        onChange={(e) => toggleSwitchHandler(e.target.checked)}
      />

      <label className="toggle-switch-label" htmlFor={id}>
        <span
          className={"toggle-switch-inner"}
          data-yes={"Yes"}
          data-no={"No"}
        />
        <span className={"toggle-switch-switch"} />
      </label>
    </div>
  );
};
export default ToggleSwitch;

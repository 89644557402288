import React, { useState, useEffect } from "react";
import SmallLoader from "./SmallLoader";

function QuoteActionButtons(props) {
  return (
    <div className="quote-action-buttons d-flex">
      {/* <div>
        <div type={"button "} className={props.disableMap.v_quote ? "disable-div" : "enable-div"} onClick={props.downloadQuotes}>
          <u>View Quote</u>
        </div>
        {props.displayQuotesLoader && (
          <div className="d-flex justify-content-center">
            <SmallLoader />
          </div>
        )}
      </div> */}
      <div>
        <div
          type={"button "}
          className={props.disableMap.g_net_rate ? "disable-div" : "enable-div"}
          onClick={props.downloadNetRates}
        >
          <u>Get Rating Summary</u>
        </div>
        {props.displayRatesLoader && (
          <div className="d-flex justify-content-center">
            <SmallLoader />
          </div>
        )}
      </div>
    </div>
  );
}

export default QuoteActionButtons;

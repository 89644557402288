import React, { Component } from "react";
import styles from "./Testimonials.module.scss";

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={styles.testimonials}>
        <p className={styles.testimonialText}>
          “Our experience with ChoiceHR has been outstanding, we previously
          processed payroll and handled HR internally with moderate success.
          ChoiceHR’s expertise helped us to refine processes that we completed
          and turned into streamlined systems that do not impede our workflow.
          Our hand-books, time keeping and HR are now tools we live by,
          outsourcing to ChoiceHR is the way to go.”
        </p>
        <p className={styles.testimonialClient}>- Nonprofit Industry Client</p>
      </div>
    );
  }
}

export default Testimonials;

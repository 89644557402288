import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import Header from "../common/Header";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import ImageBlock from "../subcompo/sections-home/ImageBlock";
import LandingFooter from "../subcompo/sections-home/LandingFooter";
import StatsBanner from "../subcompo/sections-home/StatsBanner";
import Testimonials from "../subcompo/sections-home/Testimonials";
import Services from "../subcompo/sections-home/Services";
import RequestCallBack from "../subcompo/sections-home/RequestCallBack";
import LargeCTA from "../subcompo/sections-home/LargeCTA";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import { Auth } from "aws-amplify";
import { logout } from "../../utils/common";
import styles from "./Home2.module.scss";
import { Container } from "react-bootstrap";
const numeral = require("numeral");
let landingImage = require("../../images/landing-image.jpg");

class Home2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailCampaignUserCheck: false,
      loadingData: false,
    };
  }

  handleLogout = () => {
    logout();
    this.setState({ isLoggedIn: false });
  };

  render() {
    let { isLoggedIn, loadingData, groups } = this.state;
    return (
      <>
        <Header />
        <div className="home2 align-items-center">
          <ImageBlock
            heading="Looking for HR Solutions for your business?"
            image={landingImage}
          />
          <div className={styles.contentSection}>
            <div className="px-4">
              <h2 className="text-center">
                WE MAKE IT EASY, SO YOU CAN FOCUS ON YOUR BUSINESS &amp; YOUR
                CLIENTS.
              </h2>
              <div className={`${styles.content} d-flex mt-4 pl-3`}>
                <div className={styles.info}>
                  <p>
                    Functioning as a full-service outsourced HR department, we
                    are a single source for multiple solutions aimed at
                    providing administrative relief, Fortune 500 benefits,
                    reduced liabilities, improved productivity and increased
                    profitability to our clients.
                  </p>
                  <p>
                    ChoiceHR’s Human Resource services are designed to maximize
                    employee productivity, improve employee relations and reduce
                    employer risks associated with personnel administration. Our
                    team is trained to help your organization comply with the
                    multitude of federal and state regulatory and reporting
                    requirements. ChoiceHR can assist with employee handbooks and
                    provide online training sessions for supervisors and
                    employees.
                  </p>
                  <p className="mb-0">
                    As the worksite employer, your organization retains complete
                    control of its ability to direct the daily work performance
                    of your employees while ChoiceHR is by your side to guide and
                    coach you to make the best decisions for you and your
                    organization.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.statsbanner}>
              <StatsBanner />
            </div>
          </div>
          {!checkMailCampaignUser() && (
            <div className={styles.indicationButton}>
              <Link to="/Quote">
                <LargeCTA disabled={loadingData} />
              </Link>
            </div>
          )}
          <Services />
          <RequestCallBack />
          <Testimonials />
          <LandingFooter
            isLoggedIn={isLoggedIn}
            groups={groups}
            handleLogout={this.handleLogout}
          />
        </div>
      </>
    );
  }
}

export default Home2;

import React, { useEffect, useState } from "react";
import { onlyNum } from "../../utils/common";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import { Form, InputGroup, FormControl, Button, Col } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";

const RecalculateQuote = (props) => {
  const [stateRecalculateObject, setStateRecalculateObject] = useState({});
  const [uploadProgress, setUploadProgress] = useState(false);
  const [scheduleRangeError, setScheduleRangeError] = useState({});

  useEffect(async () => {
    let _props_stateRecalculateObject = JSON.parse(JSON.stringify(props.stateRecalculateObject));

    let fetchGetScheduleDebitCreditRangePromiseList = []

    for (let state in props.stateRecalculateObject) {
      fetchGetScheduleDebitCreditRangePromiseList.push(
        new Promise((resolve, reject) => {
          axios
            .get(awsUrl + "/api/getScheduleDebitCreditRange/" + state)
            .then(async (response) => {
              let range = response.data;
              if (range[0] === 0 && range[1] === 0) {
                delete _props_stateRecalculateObject[state];
              }
              resolve();
            })
            .catch(error => {
              console.log(error);
              reject();
            })
        }));
    }

    await Promise.allSettled(fetchGetScheduleDebitCreditRangePromiseList);

    setStateRecalculateObject(_props_stateRecalculateObject);

  }, [props.stateRecalculateObject]);

  const updateStateRecalculateObject = (_state, _selected, _value) => {
    let _stateRecalculateObject = JSON.parse(JSON.stringify(stateRecalculateObject));

    _stateRecalculateObject[_state] = {
      selected: _selected,
      value: _value
    }
    setStateRecalculateObject(_stateRecalculateObject);
  }

  const saveInDb = async (requestBody) => {
    new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
        .then((res) => {
          // setUploadProgress(false);
          // setScheduleRangeError(JSON.parse(JSON.stringify(_scheduleRangeError)));
          window.location.reload();
        })
        .catch((err) => { console.log("error: ", err); reject(); });
    })
  }

  const calculateQuote = async () => {

    let address = JSON.parse(sessionStorage.getItem("address"));
    let currProspectDetails = JSON.parse(sessionStorage.getItem("currProspect"));
    let user_id = sessionStorage.getItem("user_id");
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));

    if (!address || !currProspectDetails?.peoDetails?.selectedPeo || !user_id || !quoteData) return;

    let _scheduleRangeError = Object.keys(scheduleRangeError).length > 0 ? JSON.parse(JSON.stringify(scheduleRangeError)) : {};
    let processRecaculateQuotePromiseList = [];
    let new_sdr_factor = {};
    let state_has_value = false;
    setUploadProgress(true);

    try {
      for (let state in stateRecalculateObject) {

        let sdr_factor = stateRecalculateObject[state].value;
        let creditChecked = stateRecalculateObject[state].selected;

        if (sdr_factor) {

          state_has_value = true;

          if (_scheduleRangeError[state]) {
            delete _scheduleRangeError[state];
          }

          processRecaculateQuotePromiseList.push(new Promise((resolve, reject) => {
            axios
              .get(awsUrl + "/api/getScheduleDebitCreditRange/" + state)
              .then(async (response) => {
                let range = response.data;
                let errMessage, rangeVal, check;
                if (creditChecked) {
                  errMessage = `Your credit range is 0 - ${range[1]}`;
                  rangeVal = range[1];
                  check = sdr_factor <= rangeVal && sdr_factor >= 0;
                  new_sdr_factor[state] = -parseInt(sdr_factor).toString();
                } else {
                  errMessage = `Your debit range is 0 - ${range[0]}`;
                  rangeVal = range[0];
                  check = sdr_factor <= rangeVal && sdr_factor >= 0;
                  new_sdr_factor[state] = parseInt(sdr_factor).toString();
                }
                if (!check) {
                  _scheduleRangeError[state] = errMessage;
                }
                resolve();
              })
              .catch((err) => {
                console.log("err", err);
                _scheduleRangeError[state] = "Unable to get range!";
                resolve();
              });
          }))
        } else {
          if (_scheduleRangeError[state]) {
            delete _scheduleRangeError[state];
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    if (!state_has_value) {
      alert("Empty Values!");
      setUploadProgress(false);
      return;
    }

    try {

      await Promise.all(processRecaculateQuotePromiseList);

      let uuid_carrier = [];
      let dataBody = {};

      for (let addrs of address) {
        uuid_carrier.push(`${quoteData.date}@${quoteData.uuid}_${currProspectDetails.peoDetails.selectedPeo}+${addrs}`);
      }

      dataBody = {
        user_email_id: user_id,
        uuid_carrier,
        sdr_factor: new_sdr_factor
      };

      if (Object.keys(_scheduleRangeError).length === 0) {
        const resID = await axios.post(
          awsUrl + `/api/getRecalculateQuoteId`,
          JSON.stringify({ body: dataBody })
        );

        let fetchData = (id) => {
          new Promise((resolve, reject) => {
            axios
              .get(awsUrl + `/api/getRecalculateQuote/${id}`)
              .then(async (res) => {
                if (res.data === "processing") {
                  setTimeout(() => fetchData(id), 5000);
                } else {
                  let req_body = {
                    user_email_id: dataBody.user_email_id,
                    sdr_factor: new_sdr_factor,
                    address: address,
                    timestamp: quoteData.date,
                    uuid: quoteData.uuid,
                    peo: currProspectDetails.peoDetails.selectedPeo
                  };
                  await saveInDb(req_body, _scheduleRangeError);
                }
              })
              .catch((error) => {
                console.log("error", error);
                setUploadProgress(false);
                setScheduleRangeError(JSON.parse(JSON.stringify(_scheduleRangeError)));
                resolve();
              });
          })
        };

        fetchData(resID.data.id);
      } else {
        setUploadProgress(false);
        setScheduleRangeError(JSON.parse(JSON.stringify(_scheduleRangeError)));
      }
    } catch (error) {
      alert("Error in quote recalculation!");
    }
  };

  let tableRows = [];
  let indx = 0;
  for (let state in stateRecalculateObject) {
    indx++;
    tableRows.push(
      <RecalculateQuoteRow
        state={state}
        value={stateRecalculateObject[state].value}
        creditChecked={stateRecalculateObject[state].selected}
        indx={indx}
        updateStateRecalculateObject={updateStateRecalculateObject}
        key={indx}
        scheduleRangeError={scheduleRangeError[state]}
      />
    )
  }

  tableRows.push(
    <tr>
      <td colSpan={2}>
        <button className="btn btn-primary mt-3" onClick={async e => await calculateQuote()}>Recalculate</button>
      </td>
    </tr>
  )

  return stateRecalculateObject && Object.keys(stateRecalculateObject).length > 0 && <div className="recalculate-quote">
    <LoadingOverlay active={uploadProgress} spinner>
    <table>
        <thead>
          <th style={{ width: "50px" }}>State</th>
          <th style={{ textAlign: "center" }}>Debit/Credit</th>
          <th></th>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
    </table>
    </LoadingOverlay>
  </div>
};

const RecalculateQuoteRow = ({ state, creditChecked, value, indx, updateStateRecalculateObject, scheduleRangeError }) => {

  return <tr key={indx}>
    <th>{state.toUpperCase()}</th>
    <td className="text-center">
      <Form>
        <Form.Row>
          <Col xs="auto" style={{ paddingTop: "6px", width: "100px" }}>
            <Form.Check
              type="switch"
              id={"credit-switch-" + indx}
              checked={creditChecked}
              onChange={e => updateStateRecalculateObject(state, e.target.checked, value)}
              label={creditChecked ? "Credit" : "Debit"}
            />
          </Col>
          <Col>
            <InputGroup size="sm">
              <FormControl
                type="number"
                style={
                  scheduleRangeError
                    ? { borderColor: "red" }
                    : {}
                }
                value={value}
                onChange={e => updateStateRecalculateObject(state, creditChecked, e.target.value)}
                onKeyDown={onlyNum}
                placeholder={creditChecked ? "Credit" : "Debit"}
                aria-label="Debit or Credit"
                aria-describedby="basic-addon2"
                max="100"
              />
              <InputGroup.Append>
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Row>
      </Form>
    </td>
    <td className="text-center" style={{ fontSize: "0.8rem", color: "red" }}>
      {scheduleRangeError ? scheduleRangeError : ""}
    </td>
  </tr>;
}

export default RecalculateQuote;
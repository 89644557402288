import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let [isLoggedIn, setLoggedInStatus] = useState(true);

  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn = true,
        sessionData;
      try {
        sessionData = await Auth.currentAuthenticatedUser();
        if (rest.allowedGroups) {
          let userGroups =
            sessionData.signInUserSession.accessToken.payload["cognito:groups"];
          let allowedAccess = rest.allowedGroups.some((e) =>
            userGroups.includes(e)
          );
          if (!allowedAccess) {
            isLoggedIn = false;
          }
        }
      } catch (error) {
        isLoggedIn = false;
      }
      if (!isLoggedIn) {
        setLoggedInStatus(false);
      }
    }
    checkLoggedInStatus();
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;

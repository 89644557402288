import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

const AntiPrivateRoute = ({ component: Component, ...rest }) => {
  let [isLoggedIn, setLoggedInStatus] = useState(false);
  useEffect(() => {
    async function checkLoggedInStatus() {
      let sessionData;
      try {
        sessionData = await Auth.currentAuthenticatedUser();
        console.log("(Public) rest.allowedUsers: ", sessionData);
      } catch (error) {}
      if (sessionData) {
        setLoggedInStatus(true);
      }
    }
    checkLoggedInStatus();
  }, []);

  console.log("(Public) isLoggedIn: ", isLoggedIn);

  if (isLoggedIn) sessionStorage.setItem("isLoggedInSingle", "true");

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/Quote", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default AntiPrivateRoute;

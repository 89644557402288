import React, { Component, useState, useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import Tabs from "../common/Tabs";
import ReviewItem from "../subcompo/panel/ReviewItem";
import axios from "axios";
import { Auth } from "aws-amplify";
import { awsUrl, awsUrl2 } from "../../config";
import { showErrAlert } from "../../utils/common";
import { Nav } from "react-bootstrap";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { getHeader, blobPdfFromBase64String } from "../../utils/common";
import Header from "../common/Header";
import { logout } from "../../utils/common";
import PreUnderwriterFlow from "./PreUnderwriterFlow";

async function loadNoLoss(data, setIframeContent, setLoading, setError) {
  const { company_name, contact_person, dFrom, fein } = data;

  await axios
    .post(
      awsUrl2 + "/api/getNoLossData",
      JSON.stringify({
        company: company_name,
        name: contact_person,
        dFrom,
        fein,
      })
    )
    .then((res) => {
      let pdf = res.data.data;
      let file = blobPdfFromBase64String(pdf);
      var fileURL = URL.createObjectURL(file);
      setLoading(false);
      setIframeContent(fileURL);
    })
    .catch((err) => {
      setError(err);
    });
}

function loadAcordForm(data, setIframeContent, setLoading, setError) {
  const { user_email_id, uuid_carrier, carrier } = data;
  const address = uuid_carrier.map((res) => res.split("+").reverse()[0]);
  const date = uuid_carrier[0].split("@")[0];
  let etQuoteDetails = {
    email: user_email_id,
    timestamp: date,
    carrier: carrier,
    mail_address: "",
    address: address,
    state: [data.state.toUpperCase()],
  };
  axios
    .post(awsUrl + "/api/generateAccordForm/", etQuoteDetails)
    .then((res) => {
      let pdf = res.data.data;
      let file = blobPdfFromBase64String(pdf);
      var fileURL = URL.createObjectURL(file);
      setLoading(false);
      setIframeContent(fileURL);
    })
    .catch((err) => {
      setError(err);
    });
}

async function getSignedURL(lossRunData) {
  let promise = new Promise(async (resolve, reject) => {
    axios
      .post(awsUrl + "/api/getLossRunForm", JSON.stringify(lossRunData))
      .then(async (response) => {
        let fileURL;
        if (response.data) {
          const { signedURL } = response.data;
          let res = { data: signedURL };
          await axios
            .get(signedURL, { responseType: "blob" })
            .then((res) => {
              const { fileType } = lossRunData;
              const imageFormats = ["image", "jpeg", "png", "jpg"];

              if (fileType === "pdf") {
                const newBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                fileURL = URL.createObjectURL(newBlob);
              } else if (imageFormats.includes(fileType)) {
                const newBlob = new Blob([res.data], {
                  type: "image/jpeg/png/jpg",
                });
                fileURL = URL.createObjectURL(newBlob);
              } else {
                const newBlob = new Blob([res.data], {
                  type: "text/plain",
                });
                fileURL = URL.createObjectURL(newBlob);
              }
              resolve(fileURL);
            })
            .catch((err) => {
              resolve(undefined);
            });
        } else {
          resolve(undefined);
        }
      })
      .catch((error) => {
        resolve(undefined);
      });
  });
  return promise;
}

async function loadLossRun(data, setNoLossData, setLoading, setError) {
  const { user_email_id } = data;
  let fileList = [];

  await axios
    .get(awsUrl + "/api/getUserDataStatus/" + user_email_id + "/quote")
    .then((response) => {
      console.log("response checking:", response);
      let firstCarrierData =
        response.data.carrierBasedData[
          Object.keys(response.data.carrierBasedData)
        ];
      fileList =
        firstCarrierData[Object.keys(firstCarrierData)].fileListToStore;
    })
    .catch((err) => {
      showErrAlert(err);
    });

  if ((fileList && fileList.length === 0) || !fileList) {
    setLoading(false);
    setNoLossData("No Data.");
    return;
  }

  let promiseListForURL = [];
  for (let fileListKey in fileList) {
    let lossRunData = {
      key: fileList[fileListKey].key,
      fileType: fileList[fileListKey].fileType,
    };
    promiseListForURL.push(getSignedURL(lossRunData));
  }
  let tabList = [];
  await Promise.all(promiseListForURL).then((response) => {
    let indx = 0;
    response.map((rw) => {
      tabList.push({
        tabName: `tab${indx}`,
        children: (
          <iframe
            key={`tab${indx}`}
            src={rw}
            style={{ border: "none" }}
            height="100%"
            width="100%"
          ></iframe>
        ),
      });
      indx++;
    });
  });

  let noLossData = <Tabs tabList={tabList} />;
  setLoading(false);
  setNoLossData(noLossData);
}

function LossSumaryTable(props) {
  const historical_claims = props.data;
  if (historical_claims.length === 0) {
    return <p>No data found</p>;
  }
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="per5 text-center">Year</th>
          <th className="per30">Carrier</th>
          <th className="per10 text-right">Annual Premium</th>
          <th className="per5 text-right"># of Claims</th>
          <th className="per10 text-right">Total Incurred</th>
          <th className="per10 text-right">Total Paid</th>
          <th className="per10 text-right">Total Reserves</th>
        </tr>
      </thead>
      <tbody>
        {historical_claims.map((claim) => (
          <tr key={claim.year}>
            <td className="text-left">{claim.year}</td>
            <td className="text-left">{claim.carrier}</td>
            <td className="text-right">{claim.annPre}</td>
            <td className="text-right">{claim.claim}</td>
            <td className="text-right">{claim.totInc}</td>
            <td className="text-right">{claim.totPaid}</td>
            <td className="text-right">{claim.totRes}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function DocumentModal(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [iframeContent, setIframeContent] = useState(null);
  const [lossData, setLossData] = useState(null);
  const [noLossData, setNoLossData] = useState(null);

  let modalTitle = "";
  if (props.docProperties) {
    modalTitle = {
      "no-loss": "No Loss",
      "acord-form": "Acord Form",
      "loss-run": "Loss Runs",
      "loss-summary": "Loss Summary",
    }[props.docProperties.docType];
  }

  useEffect(() => {
    if (props.docProperties === null) {
      setLoading(true);
      setIframeContent(null);
      setLossData(null);
      setError(false);
    } else {
      if (props.docProperties.docType === "no-loss") {
        loadNoLoss(
          props.docProperties.itemData,
          setIframeContent,
          setLoading,
          setError
        );
      } else if (props.docProperties.docType === "acord-form") {
        loadAcordForm(
          props.docProperties.itemData,
          setIframeContent,
          setLoading,
          setError
        );
      } else if (props.docProperties.docType === "loss-run") {
        loadLossRun(
          props.docProperties.itemData,
          setNoLossData,
          setLoading,
          setError
        );
      }
      // else if (props.docProperties.docType === "loss-summary") {
      //   if (props.docProperties.itemData.historical_claims) {
      //     setLoading(false);
      //     setLossData(props.docProperties.itemData.historical_claims);
      //   } else {
      //     setError("No loss summary available");
      //   }
      // }
    }
  }, [props.docProperties]);

  if (props.show) {
    let modalContent = "";
    if (error) {
      modalContent = <p>Document not loaded.</p>;
    } else if (loading) {
      modalContent = <p>Loading document ...</p>;
    } else if (iframeContent) {
      modalContent = (
        <iframe
          title="{modalTitle}"
          src={iframeContent}
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      );
    } else if (lossData) {
      modalContent = <LossSumaryTable data={lossData} />;
    } else if (noLossData) {
      modalContent = <div className="no-loss-model">{noLossData}</div>;
    }
    return (
      <Modal
        show={props.show}
        onHide={() => props.onHide()}
        dialogClassName="modal-full"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    );
  } else {
    return null;
  }
}

class PanelUW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_data: "",
      sortAscending: true,
      displayDocument: false,
      docProperties: null,
      selected: "tab1",
    };
  }
  componentDidMount() {
    // Auth.currentSession()
    //   .then((res) => {
    //     console.log(
    //       "Current Authenticated Response User in UW ",
    //       res.idToken.payload["cognito:username"]
    //     );
    // var carrier = res.idToken.payload["cognito:username"];
    // const USER_TOKEN = res.idToken.jwtToken;
    // const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
    axios
      .get(awsUrl + "/api/getCarrierDataStatus/" + "carrier_n@gmail.com") //, header)
      .then((response) => {
        this.setState({
          show_data: response.data,
        });
        this.setState({ disp_message: true });
      })
      .catch((err) => {
        console.log("Error in getting status :(", err);
        // showErrAlert(err);
      });
    // })
    // .catch((err) => {
    //   $("#loader").css("display", "none");
    //   showErrAlert(err);
    // });
  }

  changeSortOrder = () => {
    const { sortAscending } = this.state;
    this.setState({ sortAscending: !sortAscending });
  };

  onDisplayDocument = (docType, itemData) => {
    console.log("Displaying document ", docType);
    this.setState({
      showDocument: true,
      docProperties: {
        docType: docType,
        itemData: itemData,
      },
    });
  };

  onHideDocument = () => {
    this.setState({
      showDocument: false,
      docProperties: null,
    });
  };

  render() {
    const { show_data, sortAscending, selected } = this.state;
    const tabsMap = {
      tab1: "UnderWriter",
      tab2: "Pre UnderWriter",
    };
    let data2send = {};
    let uniqueDomainList = {};
    for (let data of show_data) {
      if (
        !(data.domain in uniqueDomainList) ||
        (data.domain in uniqueDomainList &&
          uniqueDomainList[data.domain].date < data.date)
      ) {
        if (!(data.date in data2send)) {
          data2send[data.date] = {};
        }
        if (!(data.carrier_location_data.state in data2send[data.date])) {
          data2send[data.date][data.carrier_location_data.state] = {
            uuid_carrier: [],
          };
        }

        data2send[data.date][data.carrier_location_data.state].state =
          data.carrier_location_data.state;
        data2send[data.date][data.carrier_location_data.state].carrier =
          data.carrier_location_data.carrier;
        data2send[data.date][data.carrier_location_data.state].quote_status =
          data.quote_status;
        data2send[data.date][data.carrier_location_data.state].user_email_id =
          data.user_email_id;
        data2send[data.date][data.carrier_location_data.state].review =
          data.review;
        data2send[data.date][data.carrier_location_data.state].effective_date =
          data.effective_date;
        data2send[data.date][
          data.carrier_location_data.state
        ].salesperson_email = data.salesperson_email;
        data2send[data.date][data.carrier_location_data.state].origin =
          data.origin;
        data2send[data.date][data.carrier_location_data.state].company_name =
          data.company_name;
        data2send[data.date][data.carrier_location_data.state].contact_person =
          data.contact_person;
        data2send[data.date][data.carrier_location_data.state].phone_number =
          data.phone_number;
        data2send[data.date][data.carrier_location_data.state].emod = data
          .emodStatesData.ncci.rate1
          ? data.emodStatesData.ncci.rate1.value
          : 1;
        data2send[data.date][
          data.carrier_location_data.state
        ].uuid_carrier.push(data.uuid_carrier);
        data2send[data.date][
          data.carrier_location_data.state
        ].historical_claims = data.historical_claims;
        data2send[data.date][data.carrier_location_data.state].lossData =
          data.lossData;
        data2send[data.date][data.carrier_location_data.state].domain =
          data.domain;
        data2send[data.date][data.carrier_location_data.state].dFrom =
          data.dFrom;
        data2send[data.date][data.carrier_location_data.state].fein = data.fein;

        uniqueDomainList[data.domain] = {
          date: data.date,
        };
      }
    }

    let ReviewList = [];
    let index = 0;

    let timeStampList = Object.keys(data2send);
    if (sortAscending) {
      timeStampList.sort();
    } else {
      timeStampList.sort().reverse();
    }

    for (let timestamp of timeStampList) {
      for (let state in data2send[timestamp]) {
        ReviewList.push(
          <ReviewItem
            sortAscending={sortAscending}
            key={timestamp}
            item={timestamp}
            data={data2send[timestamp][state]}
            displayDocument={this.onDisplayDocument}
          />
        );
        index++;
      }
    }

    return (
      <div>
        <div className="d-flex">
          <Header />
          {/* <div
            onClick={logout}
            className="d-flex align-items-center logout-div"
          >
            Logout
          </div> */}
        </div>
        <Nav
          variant="pills"
          defaultActiveKey={selected}
          style={{ fontSize: "16px", margin: "5px" }}
        >
          {Object.keys(tabsMap).map((tab) => (
            <Nav.Item>
              <Nav.Link
                eventKey={tab}
                onClick={() => this.setState({ selected: tab })}
              >
                {tabsMap[tab]}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        {selected === "tab1" && (
          <div style={{ margin: "5px" }}>
            <div id="uwPortal" className="mt-5">
              <div class="container-fluid">
                {ReviewList.length > 0 && (
                  <table className="table fixedHeader table-sm">
                    <thead>
                      <tr>
                        <th className="per10">Effective Date</th>
                        <th className="per10">Insured Name</th>
                        <th
                          className="per10 text-center"
                          onClick={this.changeSortOrder}
                          style={{ cursor: "pointer" }}
                        >
                          Expiration
                        </th>
                        {/* <th className="per5 text-center">Quote PDF</th> */}
                        <th className="per5 text-center">Acord App</th>
                        <th className="per5 text-center">Loss Runs</th>
                        <th className="per5 text-center">No Loss</th>
                        {/* <th className="per5 text-center">Loss Summary</th>
                    <th className="per20 text-center">
                      Schedule Debit / Credit
                    </th> */}
                        <th className="per20 text-center">Decision</th>
                        <th className="text-center">Comments</th>
                      </tr>
                    </thead>
                    <tbody>{ReviewList}</tbody>
                  </table>
                )}
                {ReviewList.length <= 0 && this.state.disp_message && (
                  <span style={{ margin: "auto" }}>
                    "No data found for this carrier"{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        {selected === "tab2" && (
          <div style={{ margin: "5px" }}>
            <div
              className="pre-underwriter"
              id="tab2"
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <PreUnderwriterFlow tabName={"tab2"} />
            </div>
          </div>
        )}
        <DocumentModal
          show={this.state.showDocument}
          docProperties={this.state.docProperties}
          onHide={() => this.onHideDocument()}
        />
        <Footer />
        <Loader />
      </div>
    );
  }
}

export default PanelUW;
